import { CognitoUserPool } from 'amazon-cognito-identity-js';
import {cognitoConfig} from './config';

var poolData = {
    UserPoolId:  cognitoConfig.userPoolId,
    ClientId: cognitoConfig.userPoolClientId
};

var userPool = new CognitoUserPool(poolData);

function retrieveToken (tokenCallback, errorCallback) {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.getSession(function sessionCallback(err, session) {
            if (err) {
                console.log(err);
                errorCallback();
            } else if (!session.isValid()) {
                console.log("Invalid session");
                errorCallback();
            } else {                 
                cognitoUser.refreshSession(session.getRefreshToken(), function refreshCallback() {
                    tokenCallback(session.getIdToken().getJwtToken());
                });                    
            }
        });
    } else {
        errorCallback();
    }
}

function GetToken () {
    return new Promise(
        (resolve, reject) => {
            retrieveToken(
                function (data) {
                    resolve(data);
                } , 
                function () {
                    reject()
                }
            )
        }
    )   
}

function SignOut() {
    userPool.getCurrentUser().signOut();
    window.location.href = "signin.html";
}

function getUserPool() {
    return userPool;
}

export const cognito = {
    GetToken,
    SignOut,
    getUserPool
};